import React, { useState } from "react";
import "./style.scss";
import { MediaItem } from "../../../ui";
import { DEFAULT_COUNT } from "../PortfolioSection";

const data = {
  en: {
    title: "We're in the media",
    media: [
      {
        title:
          "Stages of developing national applications for Flutter: From ideas to launch",
        description:
          "We are not us without mobile phones and mobile applications. For example, I can’t live a day without instant messengers, banking applications and programs for me personally, for example: Singularity App and FatSecret.",
        link: "https://vc.ru/dev/1294666-etapy-razrabotki-mobilnogo-prilozheniya-na-flutter-ot-idei-do-zapuska#Flutter-razrabotka-Voploschaem-idei-v-kod",
      },
      {
        title:
          "Choosing a technology for mobile development: why Flutter beats the competition",
        description:
          "Have you decided to create a cross-platform mobile application for your business? Great! But here's the problem - you need to choose a technology stack. React Native? Ionic? Xamarin? (May he rest in peace).",
        link: "https://vc.ru/dev/1312614-vybor-tehnologii-dlya-mobilnoi-razrabotki-pochemu-flutter-pobezhdaet-konkurentov",
      },
      {
        title:
          "Mobile application development for a restaurant. Who needs it and how much does it cost? ",
        description:
          "Of course, having a mobile application is an obvious plus for any restaurant, but it is also a serious financial and time investment, so it is very important to understand why you need it and what measurable benefits can be achieved with its help",
        link: "https://vc.ru/u/331647-elena-nazarova/636931-razrabotka-mobilnogo-prilozheniya-dlya-restorana-komu-nuzhno-i-skolko-stoit",
      },
      {
        title:
          "Why are restaurants willing to pay from 3 million rubles annually for the development and implementation of IT solutions? Foodtech Trends 2023.",
        description:
          "Unlike the traditional food industry, foodtech uses modern technologies to produce and sell quality products. These technologies include: artificial intelligence, machine learning, the Internet of Things, manufacturing products on 3D printers, and much more.",
        link: "https://vc.ru/u/331647-elena-nazarova/647606-trendy-fudteha-2023-pochemu-restorany-gotovy-platit-ot-3-mln-rubley-ezhegodno-na-razrabotku-i-vnedrenie-it-resheniy",
      },
      {
        title:
          'How did we develop the restaurant automation system "FotoBox", which helped us to cope with staff abuse?',
        description:
          '"FotoBox" is a unique product on the Russian market, which in the future may become a standard in catering companies. It will be used to control the quality of employees work, as well as to record the economic activities of restaurants and other establishments.',
        link: "https://vc.ru/u/331647-elena-nazarova/597073-kak-my-razrabotali-sistemu-avtomatizacii-restorana-fotobox-kotoraya-pomogla-spravitsya-so-zloupotrebleniyami-personala",
      },
      {
        title: "Stages of mobile application development",
        description:
          "In this article, you will find a description of the stages of mobile application development, which are called analytics, design creation, application development, testing, release and support",
        link: "https://vc.ru/u/331647-elena-nazarova/484746-etapy-razrabotki-mobilnogo-prilozheniya",
      },
      {
        title:
          "Automation of a restaurant, cafe and bar: custom design or ready-made solution",
        description:
          "This article talks about the automation of restaurants, cafes and bars, but not so much about the software familiar to all restaurateurs, such as R-keeper, IIKO and others, but more about custom development, which helps automate business processes and significantly affect the efficiency of catering enterprises.",
        link: "https://vc.ru/u/331647-elena-nazarova/594788-avtomatizaciya-restorana-kafe-i-bara-kastomnaya-razrabotka-ili-gotovoe-reshenie",
      },
      {
        title:
          "Features of application development for the food technology sector in 2023. How to create an IT product, saving time, money and nerves",
        description:
          "This article talks about the development of applications for restaurants, the foodtech market in general and, of course, what modern restaurateurs should implement in order to win the competition.",
        link: "https://vc.ru/u/331647-elena-nazarova/602672-osobennosti-razrabotki-prilozheniy-dlya-sfery-fudteh-v-2023-godu-kak-sozdat-it-produkt-sekonomiv-vremya-dengi-i-nervy",
      },
      {
        title: "Development of delivery applications for restaurants and shops",
        description:
          "Let's talk about grocery and food delivery apps from stores and restaurants. Moreover, we will focus on the practical benefits of developing our own delivery application and the possible results that can be achieved with its help. If you are just planning to launch delivery, or you are not satisfied with working through aggregators or rental applications, then we recommend spending a few minutes of time reading this article",
        link: "https://vc.ru/u/331647-elena-nazarova/660590-razrabotka-prilozheniy-dostavki-dlya-restoranov-i-magazinov",
      },
      {
        title:
          "How can I improve the quality of service or optimize business processes in a restaurant, cafe or bar?",
        description:
          "Automation of restaurants today is not “something” available exclusively to market leaders, but an integral part of the activities of any catering company. However, before we talk about which software helps restaurants optimize their work, grow, and scale, we recall that not only the restaurant business is becoming more mature and digitalized, but also competition in the entire catering industry is growing every year.",
        link: "https://vc.ru/u/331647-elena-nazarova/754387-kak-uluchshit-kachestvo-obsluzhivaniya-i-optimizirovat-biznes-processy-v-restorane-kafe-ili-bare",
      },
      {
        title:
          "How and where to find an investor for a startup / Tips for finding investments for a startup",
        description:
          "Launching your own startup is an exciting and expensive task.One can imagine how many interesting projects failed when it became clear how much production costs. However, it is quite possible to get financing for your project. Especially for those who want to know how to act in order to realize their business dreams, we decided to write an article on how to attract investment in the project.",
        link: "https://vc.ru/u/331647-elena-nazarova/470315-kak-i-gde-nayti-investora-dlya-startapa-sovety-po-poisku-investiciy-dlya-startapa",
      },
    ],
  },
  ru: {
    title: "Мы в СМИ",
    media: [
      {
        title:
          "Этапы разработки мобильного приложения на Flutter: От идеи до запуска",
        description:
          "Мы - не мы без мобильных телефонов и мобильных приложений. Я, например, не могу и дня прожить без мессенджеров, банковских приложений и полезных лично для меня программ, например: Singularity App и FatSecret.",
        link: "https://vc.ru/dev/1294666-etapy-razrabotki-mobilnogo-prilozheniya-na-flutter-ot-idei-do-zapuska#Flutter-razrabotka-Voploschaem-idei-v-kod",
      },
      {
        title:
          "Выбор технологии для мобильной разработки: почему Flutter побеждает конкурентов",
        description:
          "Вы решили создать кроссплатформенное мобильное приложение для своего бизнеса? Отлично! Но вот незадача — вам нужно выбрать технологический стек. React Native? Ionic? Xamarin? (Пусть земля ему будет пухом).",
        link: "https://vc.ru/dev/1312614-vybor-tehnologii-dlya-mobilnoi-razrabotki-pochemu-flutter-pobezhdaet-konkurentov",
      },
      {
        title:
          "Разработка мобильного приложения для ресторана. Кому нужно и сколько стоит?",
        description:
          "Безусловно, наличие мобильного приложения – это очевидный плюс для любого ресторана, однако оно же и серьезное финансовое и временное вложение, поэтому очень важно понять для себя – зачем оно нужно, и каких измеримых преимуществ можно добиться с его помощью.",
        link: "https://vc.ru/u/331647-elena-nazarova/636931-razrabotka-mobilnogo-prilozheniya-dlya-restorana-komu-nuzhno-i-skolko-stoit",
      },
      {
        title:
          "Тренды фудтеха 2023. Почему рестораны готовы платить от 3 млн. рублей ежегодно на разработку и внедрение IT-решений.",
        description:
          "В отличие от традиционной пищевой промышленности, фудтех использует современные технологии для производства и реализации качественных продуктов. Среди таких технологий: искусственный интеллект, машинное обучение, интернет вещей, изготовление продуктов на 3D-принтерах и многое другое.",
        link: "https://vc.ru/u/331647-elena-nazarova/647606-trendy-fudteha-2023-pochemu-restorany-gotovy-platit-ot-3-mln-rubley-ezhegodno-na-razrabotku-i-vnedrenie-it-resheniy",
      },
      {
        title:
          "Как мы разработали систему автоматизации ресторана «FotoBox», которая помогла справиться со злоупотреблениями персонала ?",
        description:
          "«FotoBox» является уникальным продуктом на российском рынке, который в будущем может стать стандартом на предприятиях общественного питания. Его будут использовать для контроля качества работы сотрудников, а также для учета хозяйственной деятельности ресторанов и иных заведений",
        link: "https://vc.ru/u/331647-elena-nazarova/597073-kak-my-razrabotali-sistemu-avtomatizacii-restorana-fotobox-kotoraya-pomogla-spravitsya-so-zloupotrebleniyami-personala",
      },
      {
        title: "Этапы разработки мобильного приложения",
        description:
          "В этой статье вас ждет описание этапов разработки мобильного приложения, которые называются аналитика, создание дизайна, разработка приложения, тестирование, релиз и поддержка.",
        link: "https://vc.ru/u/331647-elena-nazarova/484746-etapy-razrabotki-mobilnogo-prilozheniya",
      },
      {
        title:
          "Автоматизация ресторана, кафе и бара: кастомная разработка или готовое решение",
        description:
          "В данной статье говорится об автоматизации ресторанов, кафе и баров, однако не столько о привычном всем рестораторам программном обеспечении, таком как R-keeper, IIKO и прочем, а больше о кастомной разработке, которая помогает автоматизировать бизнес-процессы и существенно повлиять на эффективность деятельности предприятий общественного питания",
        link: "https://vc.ru/u/331647-elena-nazarova/594788-avtomatizaciya-restorana-kafe-i-bara-kastomnaya-razrabotka-ili-gotovoe-reshenie",
      },
      {
        title:
          "Особенности разработки приложений для сферы фудтех в 2023 году. Как создать IT-продукт, сэкономив время, деньги и нервы",
        description:
          "В данной статье рассказывается о разработке приложений для ресторанов, о рынке фудтех в целом и, конечно, о том, что стоит внедрят современным рестораторам, чтобы побеждать в конкурентной борьбе.",
        link: "https://vc.ru/u/331647-elena-nazarova/602672-osobennosti-razrabotki-prilozheniy-dlya-sfery-fudteh-v-2023-godu-kak-sozdat-it-produkt-sekonomiv-vremya-dengi-i-nervy",
      },
      {
        title: "Разработка приложений доставки для ресторанов и магазинов",
        description:
          "Поговорим о приложениях доставки продуктов и блюд из магазинов и ресторанов. Причем сделаем упор на практическую пользу разработки собственного приложения доставки и возможные результаты, которых можно добиться с его помощью. Если вы только планируете запускать доставку, либо же не удовлетворены работой через агрегаторы или арендные приложения, тогда советуем потратить несколько минут времени на прочтение этой статьи.",
        link: "https://vc.ru/u/331647-elena-nazarova/660590-razrabotka-prilozheniy-dostavki-dlya-restoranov-i-magazinov",
      },
      {
        title:
          "Как улучшить качество обслуживания или оптимизировать бизнес-процессы в ресторане, кафе или баре?",
        description:
          "Автоматизация ресторанов сегодня - это не “что-то” доступное исключительно лидерам рынка, а неотъемлемая часть деятельности любого предприятия общественного питания. Однако прежде чем поговорить, какое программное обеспечение помогает ресторанам оптимизировать работу, расти, и масштабироваться, напомним, что не только ресторанный бизнес становится более зрелым и диджитализированным, но и конкуренция во всей сфере общественного питания растет с каждым годом.",
        link: "https://vc.ru/u/331647-elena-nazarova/754387-kak-uluchshit-kachestvo-obsluzhivaniya-i-optimizirovat-biznes-processy-v-restorane-kafe-ili-bare",
      },
      {
        title:
          "Как и где найти инвестора для стартапа / Советы по поиску инвестиций для стартапа",
        description:
          "Запуск собственного стартапа — увлекательная и дорогостоящая задача.Можно вообразить, сколько интересных проектов потерпели неудачу, когда стало понятно, сколько стоит производство. Однако, получить финансирование для своего проекта вполне возможно. Специально для тех, кто хочет знать, как действовать, чтобы реализовать свои бизнес-мечты, мы решили написать статью о том, как привлечь инвестиции в проект.",
        link: "https://vc.ru/u/331647-elena-nazarova/470315-kak-i-gde-nayti-investora-dlya-startapa-sovety-po-poisku-investiciy-dlya-startapa",
      },
    ],
  },
};

export const MediaSection = ({ lang }) => {
  const [countView, setCountView] = useState(DEFAULT_COUNT);
  return (
    <section className="base-section media">
      <div className="container">
        <div className="title">{data[lang].title}</div>
        <div className="list-media">
          {data[lang].media.slice(0, countView).map((el, idx) => (
            <MediaItem
              key={`media-item-${idx}`}
              info={el}
              textLink={lang === "ru" ? "читать" : "read"}
            />
          ))}
        </div>
        {!!(data[lang].media.length > countView) && (
          <div
            className="btn-action"
            onClick={() => setCountView(countView + DEFAULT_COUNT)}
          >
            {lang === "ru" ? "Показать ещё" : "See all"}
          </div>
        )}
      </div>
    </section>
  );
};
