import React, { Component, createRef } from "react";
import ContactBlockNew from "../../components/ContactSection/ContactBlockNew";
import { Footer } from "../../components/sections";
import BaseHeader from "../../components/baseHeader";
import { tags } from "../../components/casesPortfolio/Cases";
import { RequestSection } from "../../components/sections";
import { ListStoreLinks } from "./ListLink";
import { ReviewBlock } from "./ReviewBlock";
import { Technologies } from "./Technologies";
import { CommentBlock } from "./Comment";
import { DiscussBox } from "./DiscussBox";
import Preloader from "../../components/Preloader/Preloader";
import { RightSection } from "./RightSection";
import { ListMenu } from "./ListMenu";
import { TagRed } from "../../components/tag";
import "./style.scss";
const getDate = (lang, key) => {
  const cases = window?.cases[lang];
  const currentData = cases.find((item) => item.id === key);
  return currentData;
};

export const scrollToBlock = (id, height) => {
  const tag = document.getElementById(id);

  if (tag) {
    window.scrollTo({
      top: tag.offsetTop - height,
      behavior: "smooth",
    });
  }
};

class CasePage extends Component {
  titleRef = createRef();
  footerRef = createRef();
  mainTitleRef = createRef();
  taskRef = createRef();
  implementationRef = createRef();
  difficultiesRef = createRef();
  resultRef = createRef();
  sidebarRef = createRef();
  reviewRef = createRef();
  technologiesRef = createRef();
  headerRef = createRef();
  state = {
    data: {},
    currentTagURL: null,
    sidebarStickyOn: "",
    heightBack: 0,
    heightHeaderCase: 0,
    heightHeaderMain: 0,
    nameCase: "",
    win: window.innerWidth,
    isShow: true,
    zoom: false,
    currentImg: -1,
  };
  componentDidMount() {
    const str = window.location.pathname;
    const sl = str.split("/").slice(0, 4);
    const keyCase = sl[3];
    this.setState({
      data: getDate(this.props.lang, keyCase),
      nameCase: keyCase,
    });
    window.addEventListener("scroll", this.stickSidebar);

    setTimeout(() => {
      this.setState({
        heightBack: this.titleRef?.current?.clientHeight,
        heightHeaderCase:
          this.headerRef?.current?.clientHeight +
          this.mainTitleRef?.current?.clientHeight,
        sidebarHeader: this.sidebarRef?.current?.clientHeight,
        currentTagURL: tags.find(
          (tag) => tag.text?.[this.props.lang] === this.state.data?.tag
        ),
      });

      window.scrollTo(0, 0);
      this.setState({ isShow: false });
    }, 500);

    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ win: window.innerWidth });
  };

  setCurrentImgZoom = (index) => {
    this.setState((prevState) => {
      if (prevState.currentImg !== -1) {
        return { currentImg: -1 };
      }
      if (prevState.currentImg === -1) {
        return { currentImg: index };
      }
    });
  };

  updateHeightHeaderMain = (height) => {
    this.setState({ heightHeaderMain: height });
  };

  openImg = (e) => {
    if (e?.target?.tagName === "IMG") {
      this.setState((prevState) => {
        if (prevState.zoom === false) {
          return { zoom: true };
        }
        if (prevState.zoom === true) {
          return { zoom: false };
        }
      });
    }
  };

  stickSidebar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      if (
        windowHeight <= this.state.heightHeaderCase &&
        this.state.heightHeaderCase
      ) {
        this.setState({
          sidebarStickyOn: "",
        });
      } else {
        this.setState({
          navbarStickyOn: "",
        });
      }

      if (
        windowHeight >= this.state.heightHeaderCase &&
        this.state.heightHeaderCase
      ) {
        this.setState({
          sidebarStickyOn: "is-sticky ",
        });
      } else {
        this.setState({
          sidebarStickyOn: "",
        });
      }

      if (
        this.footerRef.current?.getBoundingClientRect().top <
        this.state.sidebarHeader + this.state.heightHeaderMain
      ) {
        this.setState({
          navbarStickyOn: "is-static",
        });
      } else {
        this.setState({
          navbarStickyOn: "",
        });
      }
    }
  };

  render() {
    const lang = this.props.lang || "en";
    const page = this.props.page;

    return (
      <>
        {this.state.isShow === true && <Preloader />}
        <BaseHeader
          lang={lang}
          page={page}
          setHeightHeader={this.updateHeightHeaderMain}
        />
        <div className="case-page">
          <section
            ref={this.headerRef}
            id="header-case"
            className="section bg-inherit overflow-hidden "
          >
            <div
              style={{
                backgroundSize: `100%  ${
                  this.state.win > 768
                    ? this.state.heightBack + 253 + "px"
                    : "300px"
                }`,
              }}
              className="shapes-container bg-header"
            ></div>
            <div className="container">
              <div className="header-case-wrap h-100 mt-5">
                <div className="case-item">
                  <div className="case-item_img ">
                    <img
                      src={this.state.data?.imgHeading}
                      alt="Кроссплатформенное мобильное приложение для медитаций"
                    />
                  </div>
                  <div className="case-item_desc">
                    <div className="case-item_desc_logo">
                      {this.state.data?.imgLogo && (
                        <div className="case-item_logo">
                          <img
                            width="41"
                            height="41"
                            src={this.state.data?.imgLogo}
                            alt="alt"
                          />
                        </div>
                      )}
                      <div className="case-item_app">
                        {this.state.data?.logoText}
                      </div>
                    </div>

                    <div
                      ref={this.titleRef}
                      className="case-item_title"
                      dangerouslySetInnerHTML={{
                        __html: this.state.data?.description,
                      }}
                    ></div>
                    <div className="case-item_text">
                      <div className="row">
                        <div className="col-sm-12">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.data?.blockClient,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="case-section">
              <div className="container">
                <div className="mobile-store">
                  <ListStoreLinks
                    lang={this.props.lang}
                    appMarketLink={this.state.data?.appMarketLink}
                    googlePlayLink={this.state.data?.googlePlayLink}
                    appGalleryLink={this.state.data?.appGalleryLink}
                    rustoreLink={this.state.data?.rustoreLink}
                    webLink={this.state.data?.webLink}
                  />
                </div>
                <h1 className="case_main-title" ref={this.mainTitleRef}>
                  {this.state.data?.h1}
                </h1>
                <div className="case-section_body">
                  <div className="setup_left-column">
                    <div
                      ref={this.sidebarRef}
                      className={`setup-sidebar  ${this.state.sidebarStickyOn} ${this.state.navbarStickyOn} `}
                    >
                      <div className="h4">
                        {this.props.lang === "ru" ? "Содержание" : "Content"}
                      </div>
                      <ul className="setup-sidebar_menu">
                        <ListMenu
                          url="block-0"
                          text={this.props.lang === "ru" ? "Задача" : "Task"}
                          className={` ${
                            this.taskRef.current?.getBoundingClientRect().top <=
                              125 &&
                            this.taskRef.current?.getBoundingClientRect()
                              .bottom > 125
                              ? "active"
                              : ""
                          } `}
                        />
                        <ListMenu
                          url="block-1"
                          text={
                            this.props.lang === "ru"
                              ? "Реализация"
                              : "Implementation"
                          }
                          className={` ${
                            this.implementationRef.current?.getBoundingClientRect()
                              .top <= 125 &&
                            this.implementationRef.current?.getBoundingClientRect()
                              .bottom > 125
                              ? "active"
                              : ""
                          } `}
                        />
                        <ListMenu
                          url="block-2"
                          text={
                            this.props.lang === "ru"
                              ? "Сложности"
                              : "Difficulties"
                          }
                          className={` ${
                            this.difficultiesRef.current?.getBoundingClientRect()
                              .top <= 125 &&
                            this.difficultiesRef.current?.getBoundingClientRect()
                              .bottom > 125
                              ? "active"
                              : ""
                          } `}
                        />
                        <ListMenu
                          url="block-4"
                          text={
                            this.props.lang === "ru" ? "Результат" : "Result"
                          }
                          className={` ${
                            this.resultRef.current?.getBoundingClientRect()
                              .top <= 125 &&
                            this.resultRef.current?.getBoundingClientRect()
                              .bottom > 125
                              ? "active"
                              : ""
                          } `}
                        />
                        <ListMenu
                          url="tech-section"
                          text={
                            this.props.lang === "ru"
                              ? "Технологии"
                              : "Technologies"
                          }
                          className={` ${
                            this.technologiesRef.current?.getBoundingClientRect()
                              .top <= 125 &&
                            this.technologiesRef.current?.getBoundingClientRect()
                              .bottom > 125
                              ? "active"
                              : ""
                          } `}
                        />
                      </ul>

                      <div className="h4">
                        {this.props.lang === "ru"
                          ? "Теги по кейсу"
                          : "Tags by case"}
                      </div>
                      <ul className="setup-sidebar_tags tag-ul">
                        <TagRed
                          name={this.state.data?.tag}
                          link={`/portfolio/${this.props.lang}?tag=${this.state.currentTagURL?.url}`}
                        />

                        {this.state.data?.tagsCase?.map((item, index) => {
                          return (
                            <TagRed
                              key={index}
                              name={item.name}
                              link={`/portfolio/${this.props.lang}?tagcase=${item?.url}`}
                            />
                          );
                        })}
                      </ul>
                      {/* ссылки на магазины */}
                      {this.state.data?.appMarketLink ||
                      this.state.data?.googlePlayLin ||
                      this.state.data?.appGalleryLink ||
                      this.state.data?.rustoreLink ||
                      this.state.data?.webLink ? (
                        <div className="h4">
                          {this.props.lang === "ru" ? "Ссылки" : "Links"}
                        </div>
                      ) : (
                        <></>
                      )}

                      <ListStoreLinks
                        lang={this.props.lang}
                        appMarketLink={this.state.data?.appMarketLink}
                        googlePlayLink={this.state.data?.googlePlayLink}
                        appGalleryLink={this.state.data?.appGalleryLink}
                        rustoreLink={this.state.data?.rustoreLink}
                        webLink={this.state.data?.webLink}
                      />
                    </div>
                  </div>
                  <div className="setup_right-column">
                    <div ref={this.taskRef}>
                      {!!this.state.data ? (
                        <RightSection
                          id="block-0"
                          blockText={this.state.data?.blockTask}
                          onClick={(e) => {
                            this.openImg(e);
                          }}
                          className={`${
                            this.state.zoom === true ? "zoom" : "block-img"
                          }`}
                        >
                          <DiscussBox
                            lang={this.props.lang}
                            onClick={() =>
                              scrollToBlock(
                                "contacts",
                                this.state.heightHeaderMain
                              )
                            }
                          />
                        </RightSection>
                      ) : (
                        <p>Кейс не найден</p>
                      )}
                    </div>
                    <div ref={this.implementationRef}>
                      <RightSection
                        id="block-1"
                        blockText={this.state.data?.blockImplementation}
                        blockHeader={this.state.data?.hImplementationt}
                        onClick={(e) => {
                          this.openImg(e);
                        }}
                        className={`${
                          this.state.zoom === true ? "zoom" : "block-img"
                        }`}
                      >
                        {this.state.data?.imgList?.length > 0 && (
                          <div className="row case-img_row">
                            {this.state.data?.imgList?.map((img, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`${
                                    this.state.currentImg === index
                                      ? "lightbox show-img"
                                      : "col-sm-4"
                                  }`}
                                >
                                  <img
                                    alt="alt"
                                    onClick={() => {
                                      this.setCurrentImgZoom(index);
                                    }}
                                    className={`${
                                      this.state.currentImg === index
                                        ? "zoom-img"
                                        : "zoom-not"
                                    }`}
                                    src={img}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </RightSection>
                    </div>
                    <div ref={this.difficultiesRef}>
                      <RightSection
                        id="block-2"
                        blockText={this.state.data?.blockDifficulties}
                        blockHeader={this.state.data?.hDifficultiest}
                        onClick={(e) => {
                          this.openImg(e);
                        }}
                        className={`${
                          this.state.zoom === true ? "zoom" : "block-img"
                        }`}
                      >
                        {this.state.data?.blockManedger && (
                          <CommentBlock
                            lang={this.props.lang}
                            data={this.state.data}
                          />
                        )}
                      </RightSection>
                    </div>
                    <div ref={this.resultRef}>
                      <RightSection
                        id="block-4"
                        blockText={this.state.data?.blockResult}
                        blockHeader={this.state.data?.hResult}
                        onClick={(e) => {
                          this.openImg(e);
                        }}
                        className={`${
                          this.state.zoom === true ? "zoom" : "block-img"
                        }`}
                      >
                        {this.state.data?.blockReview && (
                          <ReviewBlock data={this.state.data} />
                        )}
                      </RightSection>
                    </div>
                    <div ref={this.technologiesRef}>
                      <RightSection
                        id="tech-section"
                        blockHeader={
                          this.props.lang === "ru"
                            ? "Технологии"
                            : "Technologies"
                        }
                      >
                        <Technologies
                          lang={this.props?.lang}
                          data={this.state.data}
                        />
                      </RightSection>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div ref={this.footerRef}></div>
        {this.state.zoom === true && (
          <div
            onClick={(e) => {
              this.openImg(e);
            }}
            className={`${"lightbox-modal show-img-modal"}`}
          ></div>
        )}
        <ContactBlockNew lang={lang} page={page} />
        <RequestSection
          lang={lang}
          page={page}
          nameCase={this.state.nameCase}
        />
        <Footer lang={lang} />
      </>
    );
  }
}

export default CasePage;
