import React, { useState } from "react";
import "./style.scss";

export const UploadFile = ({
  textBtn,
  setFile,
  placeholder,
  textInfo,
  fileName,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const changFile = (e) => {
    const file = e.target.files && e.target.files[0];
    if (file && file.size < 10000000) {
      setErrorMessage("");
      const reader = new FileReader();
      reader.onload = (e) => {
        var binaryData = e.target.result;
        var base64String = window.btoa(binaryData);
        setFile(base64String, file.name);
      };
      reader.readAsBinaryString(file);
    }
    if (file && file.size > 10000000) {
      setErrorMessage("Слишком большой файл");
    }
  };

  return (
    <div className="wrapper-upload-block">
      <div className="block-upload">
        <label htmlFor="upload-btn" className="label-upload">
          <img src="/img/clip.svg" alt="" />
          <span>{textBtn}</span>
          <input
            onChange={changFile}
            type="file"
            id="upload-btn"
            className="file"
            name="file"
          />
        </label>
        <div className={`name-file ${fileName ? "selected" : ""}`}>
          {fileName || placeholder}
        </div>
      </div>
      <div className="info-message">
        <img src="/img/info.svg" alt="" />
        <span>{textInfo}</span>
      </div>
      <div className="error-mes">{errorMessage}</div>
    </div>
  );
};
