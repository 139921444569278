import Modal from "react-bootstrap/Modal";
import React from "react";
import './style.scss'

export const BaseModal = ({ open, close, msg, img, children }) => {
  return (
    <Modal show={open} onHide={close} className="base-modal">
      <Modal.Header closeButton>
        <Modal.Title>
          {!!img && <img src={img} alt="" />}
          <span>{msg}</span>
        </Modal.Title>
      </Modal.Header>
      {!!children && <Modal.Body>{children}</Modal.Body>}
    </Modal>
  );
};
