import React from "react";
import "./style.scss";

export const Employee = ({ info, onMouseEnter, activeId }) => {
  return (
    <div className={`employee ${activeId === info.id ? "active" : ""}`}>
      <img src={info.img} alt="" onMouseEnter={onMouseEnter} />
      <div className="short-info">
        <div className="name">{info.name}</div>
        <div className="position">{info.position}</div>
      </div>
    </div>
  );
};
