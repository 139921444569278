import React from "react";
import "./style.scss";

export const RightSection = ({
  id,
  blockText,
  blockHeader,
  children,
  className,
  onClick,
}) => {
  return (
    <div className="setup_item-box section">
      <span id={id} />
      {blockHeader && <h2>{blockHeader}</h2>}
      <p
        onClick={onClick}
        className={className}
        dangerouslySetInnerHTML={{
          __html: blockText,
        }}
      />
      {children}
    </div>
  );
};
