import React from "react";
import "./style.scss";

export const PrivacyPolicy = ({ lang }) => {
  const isRu = lang === "ru";
  return (
    <section>
      <div className="container">
        <h4 className="privacy-header">
          <strong>
            <b>
              {isRu
                ? "Политика в отношении обработки персональных данных"
                : "Personal Data Processing Policy"}
            </b>
          </strong>
        </h4>
        <h5>
          <strong>
            <b>1. {isRu ? "Общие положения" : "General provisions"}</b>
          </strong>
        </h5>
        <p>
          {isRu
            ? `Настоящая политика обработки персональных данных составлена в
          соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ  "О персональных данных" и определяет порядок обработки
          персональных данных и меры по обеспечению безопасности персональных
          данных, предпринимаемые Индивидуальным предпринимателем Жиленасом
          Владасом Витауто (далее - Оператор).`
            : `This Personal data processing policy is drawn up in accordance with the requirements of Federal Law No. 152-FZ of 27.07.2006 "On Personal Data" and defines the procedure for processing personal data and measures taken to ensure the security of personal dataIndividual entrepreneur Gilenas Vladas Vytautas (hereinafter referred to as the Operator).`}
        </p>
        <p>
          1.1.{" "}
          {isRu
            ? `Оператор ставит своей важнейшей целью и условием осуществления
          своей деятельности соблюдение прав и свобод человека и гражданина при
          обработке его персональных данных, в том числе защиты прав на
          неприкосновенность частной жизни, личную и семейную тайну.`
            : `The Operator sets as its most important goal and condition for carrying out its activities the observance of human and civil rights and freedoms when processing their personal data, including the protection of the rights to privacy, personal and family secrets.`}
        </p>
        <p>
          1.2.
          <span>
            {isRu
              ? ` Настоящая политика Оператора в отношении обработки персональных
              данных (далее - Политика) применяется ко всей информации, которую
              Оператор может получить о посетителях веб-сайта `
              : ` This policy of the Operator regarding the processing of personal
              data (hereinafter referred to as the Policy) applies to all
              information that the Operator can receive about visitors to the
              website `}
            <a
              href={
                isRu
                  ? "https://mobile.itfox-web.com"
                  : "https://mobile.itfox-web.com/main/en"
              }
              style={{ color: "#FFa910" }}
              target="_blank"
              rel="noreferrer"
            >
              https://mobile.itfox-web.com
            </a>
            ;
          </span>
        </p>
        <h5>
          <strong>
            <b>
              2.{" "}
              {isRu
                ? `Основные понятия, используемые в Политике`
                : `Basic concepts used in the Policy`}
            </b>
          </strong>
        </h5>
        <p>
          2.1.{" "}
          {isRu
            ? `Автоматизированная обработка персональных данных -
          обработка персональных данных с помощью средств вычислительной
          техники;`
            : `Automated processing of personal data – processing of personal data using computer technology;`}
        </p>
        <p>
          2.2.{" "}
          {isRu
            ? `Блокирование персональных данных - временное прекращение
          обработки персональных данных (за исключением случаев, если обработка
          необходима для уточнения персональных данных);`
            : `Blocking of personal data – temporary termination of the processing of personal data (except in cases where the processing is necessary to clarify personal data);`}
        </p>
        <p>
          2.3.
          <span>
            {isRu
              ? ` Веб-сайт - совокупность графических и информационных материалов, а
              также программ для ЭВМ и баз данных, обеспечивающих их доступность
              в сети интернет по сетевому адресу `
              : ` Website – a set of graphic and informational materials, as well as
              computer programs and databases that ensure their availability on
              the Internet at the network address `}
            <a
              href={
                isRu
                  ? "https://mobile.itfox-web.com"
                  : "https://mobile.itfox-web.com/main/en"
              }
              style={{ color: "#FFa910" }}
              target="_blank"
              rel="noreferrer"
            >
              https://mobile.itfox-web.com
            </a>
            ;
          </span>
        </p>
        <p>
          2.4.{" "}
          {isRu
            ? `Информационная система персональных данных - совокупность
          содержащихся в базах данных персональных данных, и обеспечивающих их
          обработку информационных технологий и технических средств;`
            : `Personal data information system — a set of personal data contained in databases and information technologies and technical means that ensure their processing;`}
        </p>
        <p>
          2.5.{" "}
          {isRu
            ? `Обезличивание персональных данных - действия, в результате
          которых невозможно определить без использования дополнительной
          информации принадлежность персональных данных конкретному Пользователю
          или иному субъекту персональных данных;`
            : `Depersonalization of personal data — actions that make it impossible to determine whether personal data belongs to a specific User or other personal data subject without using additional information;`}
        </p>
        <p>
          2.6.{" "}
          {isRu
            ? `Обработка персональных данных - любое действие (операция)
          или совокупность действий (операций), совершаемых с использованием
          средств автоматизации или без использования таких средств с
          персональными данными, включая сбор, запись, систематизацию,
          накопление, хранение, уточнение (обновление, изменение), извлечение,
          использование, передачу (распространение, предоставление, доступ),
          обезличивание, блокирование, удаление, уничтожение персональных
          данных;`
            : `Personal data processing – any action (operation) or a set of actions (operations) performed with or without the use of automation tools with personal data, including collection, recording, systematization,accumulation, storage, clarification (updating, modification), extraction, use, transfer (distribution, provision access), depersonalization, blocking, deletion, destruction of personal data;`}
        </p>
        <p>
          2.7.{" "}
          {isRu
            ? `Оператор - государственный орган, муниципальный орган,
          юридическое или физическое лицо, самостоятельно или совместно с
          другими лицами организующие и (или) осуществляющие обработку
          персональных данных, а также определяющие цели обработки персональных
          данных, состав персональных данных, подлежащих обработке, действия
          (операции), совершаемые с персональными данными;`
            : `Operator – a state body, municipal body, legal entity or individual that independently or jointly with other persons organizes and / or performs the processing of personal data, as well as determines the purposes of processing personaldata, the composition of personal data to be processed, actions (operations) performed with personal data;`}
        </p>
        <p>
          2.8.{" "}
          <span>
            {isRu
              ? ` Персональные данные - любая информация, относящаяся прямо или
              косвенно к определенному или определяемому Пользователю веб-сайта `
              : ` Personal Data – any information related directly or indirectly to
              a specific or identifiable User of the website `}
            <a
              href={
                isRu
                  ? "https://mobile.itfox-web.com"
                  : "https://mobile.itfox-web.com/main/en"
              }
              style={{ color: "#FFa910" }}
              target="_blank"
              rel="noreferrer"
            >
              https://mobile.itfox-web.com
            </a>
            ;
          </span>
        </p>
        <p>
          2.9.{" "}
          <span>
            {isRu
              ? ` Пользователь - любой посетитель веб-сайта `
              : ` User – any visitor to the website `}
            <a
              href={
                isRu
                  ? "https://mobile.itfox-web.com"
                  : "https://mobile.itfox-web.com/main/en"
              }
              style={{ color: "#FFa910" }}
              target="_blank"
              rel="noreferrer"
            >
              https://mobile.itfox-web.com
            </a>
            ;
          </span>
        </p>
        <p>
          2.10.{" "}
          {isRu
            ? `Предоставление персональных данных - действия,
          направленные на раскрытие персональных данных определенному лицу или
          определенному кругу лиц;`
            : `Provision of personal data – actions aimed at disclosing personal data to a certain person or a certain group of persons;`}
        </p>
        <p>
          2.11.{" "}
          {isRu
            ? `Распространение персональных данных - любые действия,
          направленные на раскрытие персональных данных неопределенному кругу
          лиц (передача персональных данных) или на ознакомление с персональными
          данными неограниченного круга лиц, в том числе обнародование
          персональных данных в средствах массовой информации, размещение в
          информационно-телекоммуникационных сетях или предоставление доступа к
          персональным данным каким-либо иным способом;`
            : `Dissemination of personal data – any actions aimed at disclosing personal data to an indefinite group of persons (transfer of personal data) or at making personal data available to an unlimited number of persons, includingpublishing personal data in the mass media, posting it in information and telecommunications networks, or providing access to personal data in any other way using this method.`}
        </p>
        <p>
          2.12.{" "}
          {isRu
            ? `Трансграничная передача персональных данных - передача
          персональных данных на территорию иностранного государства органу
          власти иностранного государства, иностранному физическому или
          иностранному юридическому лицу;`
            : `Cross-border transfer of personal data – transfer of personal data to the territory of a foreign state to an authority of a foreign state, a foreign individual or a foreign legal entity;`}
        </p>
        <p>
          2.13.{" "}
          {isRu
            ? `Уничтожение персональных данных - любые действия, в
          результате которых персональные данные уничтожаются безвозвратно с
          невозможностью дальнейшего восстановления содержания персональных
          данных в информационной системе персональных данных и (или)
          уничтожаются материальные носители персональных данных.`
            : `Destruction of personal data – any actions, as a result of which personal data is permanently destroyed with the impossibility of further restoration of the content of personal data in the personal data information system and (or)the material carriers of personal data are destroyed.`}
        </p>
        <h5>
          <strong>
            <b>
              3.{" "}
              {isRu
                ? `Оператор может обрабатывать следующие персональные данные
              Пользователя`
                : `The Operator may process the following personal data of the User`}
            </b>
          </strong>
        </h5>
        <p>
          3.1.{" "}
          {isRu
            ? ` Фамилия, имя, отчество;`
            : ` Last name, first name, patronymic;`}
        </p>
        <p>3.2. {isRu ? `Электронный адрес;` : `Email address;`}</p>
        <p>3.3. {isRu ? `Номера телефонов;` : `Phone numbers.`}</p>
        <p>
          3.4.{" "}
          {isRu
            ? `Также на сайте происходит сбор и обработка обезличенных данных о
          посетителях (в т.ч. файлов cookie) с помощью сервисов
          интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).`
            : `Also on site is the collection and processing of anonymous data about visitors (including cookies) using Internet statistics (Yandex Metric and Google Analytics and others).`}
        </p>
        <p>
          3.5.{" "}
          {isRu
            ? `Вышеперечисленные данные далее по тексту Политики объединены
          общим понятием Персональные данные.`
            : `The above-mentioned data is referred to as Personal Data in the following terms of the Policy.`}
        </p>
        <h5>
          <strong>
            <b>
              4.{" "}
              {isRu
                ? `Цели обработки персональных данных`
                : `Purposes of personal data processing`}
            </b>
          </strong>
        </h5>
        <p>
          4.1.{" "}
          {isRu
            ? `Цель обработки персональных данных Пользователя - информирование Пользователя посредством отправки
          электронных писем; заключение, исполнение и прекращение договоров
          оказания услуг.`
            : `The purpose of processing the User's personal data is to inform the User by sending e-mails; to conclude, execute and terminate service contracts.`}
        </p>
        <p>
          4.2.
          <span>
            {isRu
              ? ` Также Оператор имеет право направлять Пользователю уведомления о
              новых продуктах и услугах, специальных предложениях и различных
              событиях. Пользователь всегда может отказаться от получения
              информационных сообщений, направив Оператору письмо на адрес
              электронной почты `
              : ` The Operator also has the right to send notifications to the User
              about new products and services, special offers and various
              events. The user can always refuse to receive informational
              messages by sending an email to the Operator's e-mail address `}
            <a
              href="mailto:hello@itfox-web.com"
              style={{ color: "#FFa910" }}
              target="_blank"
              rel="noreferrer"
            >
              hello@itfox-web.com
            </a>
            {isRu
              ? ` с пометкой "Отказ от уведомлений о новых продуктах и услугах и
              специальных предложениях"`
              : `marked "Unsubscribe from notifications about new products and
              services and special offers"`}
          </span>
        </p>
        <p>
          4.3.{" "}
          {isRu
            ? `Обезличенные данные Пользователей, собираемые с помощью сервисов
          интернет-статистики, служат для сбора информации о действиях
          Пользователей на сайте, улучшения качества сайта и его содержания.`
            : `Depersonalized User data collected through Internet statistics services is used to collect information about User actions on the site, improve the quality of the site and its content.`}
        </p>
        <h5>
          <strong>
            <b>
              5.{" "}
              {isRu
                ? `Правовые основания обработки персональных данных`
                : `Legal grounds for processing personal data`}
            </b>
          </strong>
        </h5>
        <p>
          5.1.
          <span>
            {isRu
              ? ` Оператор обрабатывает персональные данные Пользователя только в
              случае их заполнения и/или отправки Пользователем самостоятельно
              через специальные формы, расположенные на сайте `
              : ` The Operator processes the User's personal data only if they are
              filled in and / or sent by the User independently through special
              forms located on the site `}
            <a
              href={
                isRu
                  ? "https://mobile.itfox-web.com"
                  : "https://mobile.itfox-web.com/main/en"
              }
              style={{ color: "#FFa910" }}
              target="_blank"
              rel="noreferrer"
            >
              https://mobile.itfox-web.com
            </a>
            {isRu
              ? `. Заполняя соответствующие формы и/или отправляя свои персональные
              данные Оператору, Пользователь выражает свое согласие с данной
              Политикой.`
              : `. By filling out the relevant forms and / or sending their
              personal data to the Operator, the User agrees to this Policy.`}
          </span>
        </p>
        <p>
          5.2.{" "}
          {isRu
            ? `Оператор обрабатывает обезличенные данные о Пользователе в
          случае, если это разрешено в настройках браузера Пользователя
          (включено сохранение файлов cookie и использование
          технологии JavaScript).`
            : `The Operator processes depersonalized data about the User if this is allowed in the User's browser settings (storing cookies and using JavaScript technology is enabled).`}
        </p>
        <h5>
          <strong>
            <b>
              6.{" "}
              {isRu
                ? `Порядок сбора, хранения, передачи и других видов обработки
              персональных данных`
                : `Procedure for collecting, storing, transmitting and other types of personal data processing`}
            </b>
          </strong>
        </h5>
        <p>
          {isRu
            ? `Безопасность персональных данных, которые обрабатываются Оператором,
          обеспечивается путем реализации правовых, организационных и
          технических мер, необходимых для выполнения в полном объеме требований
          действующего законодательства в области защиты персональных данных.`
            : `The security of personal data processed by the Operator is ensured by implementing legal, organizational and technical measures necessary to fully comply with the requirements of the current legislation in the field of personaldata protection.`}
        </p>
        <p>
          6.1.{" "}
          {isRu
            ? `Оператор обеспечивает сохранность персональных данных и принимает
          все возможные меры, исключающие доступ к персональным данным
          неуполномоченных лиц.`
            : `The Operator ensures the security of personal data and takes all possible measures that exclude access to personal data of unauthorized persons.`}
        </p>
        <p>
          6.2.{" "}
          {isRu
            ? `Персональные данные Пользователя никогда, ни при каких условиях
          не будут переданы третьим лицам, за исключением случаев, связанных с
          исполнением действующего законодательства.`
            : `The User's personal data will never, under any circumstances, be transferred to third parties, except in cases related to the implementation of current legislation.`}
        </p>
        <p>
          6.3.
          <span>
            {isRu
              ? ` В случае выявления неточностей в персональных данных, Пользователь
              может актуализировать их самостоятельно, путем направления
              Оператору уведомление на адрес электронной почты Оператора `
              : ` If inaccuracies in personal data are identified, the User can
              update them independently by sending a notification to the
              Operator's email address `}
            <a
              href="mailto:hello@itfox-web.com"
              style={{ color: "#FFa910" }}
              target="_blank"
              rel="noreferrer"
            >
              hello@itfox-web.com
            </a>
            {isRu
              ? ` с пометкой "Актуализация персональных данных"`
              : `marked "Updating personal data"`}
          </span>
        </p>
        <p>
          6.4.
          <span>
            {isRu
              ? ` Срок обработки персональных данных является неограниченным.
              Пользователь может в любой момент отозвать свое согласие на
              обработку персональных данных, направив Оператору уведомление
              посредством электронной почты на электронный адрес Оператора `
              : ` The term of personal data processing is unlimited. The User can
              withdraw their consent to the processing of personal data at any
              time by sending a notification to the Operator via email to the
              Operator's email address `}
            <a
              href="mailto:hello@itfox-web.com"
              style={{ color: "#FFa910" }}
              target="_blank"
              rel="noreferrer"
            >
              hello@itfox-web.com
            </a>
            {isRu
              ? `с пометкой "Отзыв согласия на обработку персональных данных`
              : `marked "Revocation of consent to the processing of personal data`}
          </span>
        </p>
        <h5>
          <strong>
            <b>
              7.{" "}
              {isRu
                ? `Трансграничная передача персональных данных`
                : `Cross-border transfer of personal data`}
            </b>
          </strong>
        </h5>
        <p>
          7.1.{" "}
          {isRu
            ? `Оператор до начала осуществления трансграничной передачи
          персональных данных обязан убедиться в том, что иностранным
          государством, на территорию которого предполагается осуществлять
          передачу персональных данных, обеспечивается надежная защита прав
          субъектов персональных данных.`
            : `The Operator must ensure that the rights of personal data subjects are reliably protected by the foreign State to whose territory it is intended to transfer personal data prior to the start of cross-border transferof personal data.`}
        </p>
        <p>
          7.2.{" "}
          {isRu
            ? `Трансграничная передача персональных данных на территории
          иностранных государств, не отвечающих вышеуказанным требованиям, может
          осуществляться только в случае наличия согласия в письменной форме
          субъекта персональных данных на трансграничную передачу его
          персональных данных и/или исполнения договора, стороной которого
          является субъект персональных данных.`
            : `Cross-border transfer of personal data on the territory of foreign states that do not meet the above requirements can only be carried out if the personal data subject has written consent to the cross-border transfer of his/her personal data and / or the execution of a contract to which the personal data subject is a party.`}
        </p>
        <h5>
          <strong>
            <b>8. {isRu ? `Заключительные положения` : `Final provisions`}</b>
          </strong>
        </h5>
        <p>
          8.1.
          <span>
            {isRu
              ? ` Пользователь может получить любые разъяснения по интересующим
              вопросам, касающимся обработки его персональных данных,
              обратившись к Оператору с помощью электронной почты `
              : ` The User can get any clarifications on the issues of interest
              related to the processing of his personal data by contacting the
              Operator via e-mail `}
            <a
              href="mailto:hello@itfox-web.com"
              style={{ color: "#FFa910" }}
              target="_blank"
              rel="noreferrer"
            >
              hello@itfox-web.com
            </a>
            .
          </span>
        </p>
        <p>
          8.2.{" "}
          {isRu
            ? `В данном документе будут отражены любые изменения политики
          обработки персональных данных Оператором. Политика действует бессрочно
          до замены ее новой версией.`
            : `This document will reflect any changes to the Operator's personal data processing policy. The policy is valid indefinitely until it is replaced with a new version.`}
        </p>
        <p>
          8.3.
          <span>
            {isRu
              ? ` Актуальная версия Политики в свободном доступе расположена в сети
              Интернет по адрес `
              : ` The current version of the Policy is freely available on the
              Internet at `}
            <a
              href={`https://mobile.itfox-web.com/privacy-policy/${lang}`}
              style={{ color: "#FFa910" }}
              target="_blank"
              rel="noreferrer"
            >
              https://mobile.itfox-web.com/privacy-policy
            </a>
            ;
          </span>
        </p>
        <p>&nbsp;</p>
      </div>
    </section>
  );
};
