import React, { useState } from "react";
import "./style.scss";
import { Employee } from "../../../ui";

const data = {
  en: {
    title: "Who you will contact?",
    team: [
      {
        id: "1",
        img: "/img/elena-foto-team.webp",
        name: "Elena Nazarova",
        position: "Chief business development officer",
      },
      {
        id: "2",
        img: "/img/andrey-foto-team.webp",
        name: "Andrey Ivanov",
        position: "Executive director",
      },
      {
        id: "3",
        img: "/img/vladas-foto-team.webp",
        name: "Vladas Zhilenas",
        position: "Chief technical officer",
      },
      {
        id: "4",
        img: "/img/vecheslav-foto-team.webp",
        name: "Vyacheslav Cherkasyuk",
        position: "Head of Project Department",
      },
      {
        id: 5,
        img: "/img/nadezhda-foto-team.webp",
        name: "Nadezhda Ivanova",
        position: "Head of Mobile Development Department",
      }
    ],
  },
  ru: {
    title: "С кем вы будете общаться?",
    team: [
      {
        id: "1",
        img: "/img/elena-foto-team.webp",
        name: "Елена Назарова",
        position: "Директор по развитию",
      },
      {
        id: "2",
        img: "/img/andrey-foto-team.webp",
        name: "Андрей Иванов",
        position: "Исполнительный директор",
      },
      {
        id: "3",
        img: "/img/vladas-foto-team.webp",
        name: "Владас Жиленас",
        position: "Технический директор",
      },
      {
        id: "4",
        img: "/img/vecheslav-foto-team.webp",
        name: "Вячеслав Черкасюк",
        position: "Руководитель проектного отдела",
      },
      {
        id: 5,
        img: "/img/nadezhda-foto-team.webp",
        name: "Надежда Иванова",
        position: "Руководитель отдела мобильной разработки",
      },
    ],
  },
};

export const TeamSection = ({ lang }) => {
  const [activeId, setActiveId] = useState(data[lang].team[1].id);
  return (
    <section className="base-section team">
      <div className="container text-center">
        <div className="title">{data[lang].title}</div>
        <div className="team-block">
          {data[lang].team.map((item, idx) => (
            <Employee
              info={item}
              key={`employee-${idx}`}
              activeId={activeId}
              onMouseEnter={() => setActiveId(item.id)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
