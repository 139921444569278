import React, { useEffect } from "react";

export const ListMenu = ({ className, url, text }) => {
  useEffect(() => {
    console.log(className);
  }, [className]);

  return (
    <li>
      <div
        className={`link-sidebar-item ${className}`}
        onClick={() => document.getElementById(url)?.scrollIntoView()}
      >
        {text}
      </div>
    </li>
  );
};
