import React from "react";
import "./style.scss";

export const Spinner = () => {
  return (
    <div className="wrapper-spinner">
      <span className="spinner"/>
    </div>
  )
};
