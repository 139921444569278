import React, { Component } from "react";
import { Link } from "react-router-dom";
class TagBlock extends Component {
  render() {
    return (
      <Link
        to={this.props.linkUrl}
        onClick={this.props.onClick}
        className={`mt-2 tag ${this.props.class}`}
      >
        <img width="40" height="40" src={this.props.tag.img} alt="" />
        <div>{this.props.tag.text[this.props.lang]}</div>
      </Link>
    );
  }
}

export default TagBlock;
