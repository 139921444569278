import React from "react";
import "./style.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { LinksNetwork } from "../../../pages/Notification/LinksNetwork";

const footerData = {
  en: {
    copyrights: `Copyrights © 2009-${new Date()
      .getFullYear()
      .toString()} ITFOX`,
    text: "Subscribe to our social networks",
    privacy: "Privacy policy",
    main: "Main",
    cases: "Cases",
  },
  ru: {
    copyrights: `Авторское право © 2009-${new Date()
      .getFullYear()
      .toString()} IT FOX. Все права защищены.`,
    text: "Подписывайтесь на наши соц. сети",
    privacy: "Политика конфиденциальности",
    main: "Главная",
    cases: "Кейсы",
  },
};

export const Footer = ({ lang }) => {
  return (
    <footer className="footer">
      <section className="base-section">
        <div className="container">
          <div className="wrapper-logo">
            <img
              className="brand-logo"
              src="/img/ITWEBFOX WHITE_TXT2FIX_NEW.svg"
              alt="brand-logo"
            />
            <div className="heading">{footerData[lang].text}</div>
          </div>
          <div className="wrapper-info">
            <div className="item-info">
              <LinksNetwork color="#141414" bg="#fff" />
              <Link
                // target="_blank"
                to={`/privacy-policy/${lang}`}
                className="link"
              >
                {footerData[lang].privacy}
              </Link>
              <div className="copyrights">{footerData[lang].copyrights}</div>
            </div>
            <div className="item-info">
              <LinksNetwork color="#141414" bg="#fff" />
              <Link to={`/main/${lang}`} className="link">
                {footerData[lang].main}
              </Link>
              <Link to={`/portfolio/${lang}`} className="link">
                {footerData[lang].cases}
              </Link>
              <a href="tel:+79288542462" className="link">
                +7 (928) 854-24-62
              </a>
              <a href="mailto:hello@itfox-web.com" className="link">
                hello@itfox-web.com
              </a>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};
