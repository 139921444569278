import React from "react";
import "./style.scss";
import BaseHeader from "../../components/baseHeader";
import { Footer } from "../../components/sections";
import { Link } from "react-router-dom";
import { LinkMessenger } from "../../ui";
export const NotFound = ({ page, lang }) => {
  const isRu = lang === "ru";

  return (
    <div className=" main root not-found-page-wrapper">
      <BaseHeader
        lang={lang}
        page={page}
        isNotification={true}
        is404={true}
        false
      />
      <div className="not-found-back">
        <div className="container">
          <div className="not-found-content-wrapper">
            <div className="not-found-status">
              <p className="not-found-status-404">
                {isRu ? "Упс... " : "Something went wrong..."}
              </p>
              <p className="not-found-status-text">
                {isRu
                  ? "Страницы, которую вы ищете, не существует"
                  : "The page you are on does not exist"}
              </p>
            </div>
            <div className="wrapper-symbol-mobile">
              <div className="bg-circle" />
              <img src="/img/fox-404.svg" alt="" />
            </div>
            <div className="not-found-description">
              <div className="not-found-info">
                <p className="not-found-status-404">
                  {isRu ? "Вы можете" : "You can:"}{" "}
                </p>
                <p className="not-found-status-text">
                  {isRu ? "Вернуться на " : "Return to"}{" "}
                  <Link target="_blank" to={`/main/${lang}`} className="link">
                    {isRu ? "главную страницу" : "or just write to us:"}{" "}
                  </Link>
                </p>
                <p className="not-found-status-text">
                  {isRu ? "Изучить наши " : "Or study our"}{" "}
                  <Link target="_blank" to={`/main/${lang}`} className="link">
                    {isRu ? "кейсы" : "or just write to us:"}
                  </Link>
                </p>
                <div className="social-link">
                  <p className="not-found-status-text">
                    {isRu ? "Или просто напишите нам:" : "or just write to us:"}{" "}
                  </p>
                  <div className="row-block">
                    <LinkMessenger
                      link="https://t.me/ITfoxweb"
                      img="/img/telegram-white.svg"
                      text="Telegram"
                      ariaLabel="Our Telegram"
                    />
                    <LinkMessenger
                      link="https://api.whatsapp.com/send/?phone=79288542462"
                      img="/img/whatsapp-white.svg"
                      text="WhatsApp"
                      ariaLabel="Our WhatsApp"
                    />
                  </div>
                </div>
              </div>
              <div className="wrapper-symbol">
                <div className="bg-circle" />
                <img src="/img/fox-404.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer lang={lang} />
    </div>
  );
};
