import React from "react";
import "./style.scss";

export const CommentBlock = ({ lang, data }) => {
  return (
    <div className="comment-project_item">
      <div className="comment-project_img">
        <img alt="alt" src={data?.imgManedger} />
      </div>
      <div className="comment-project_desc">
        <div className="h4">
          {lang === "ru"
            ? "Комментарий о проекте"
            : "Comment about the project"}
        </div>
        <span className="comment-project_name">{data?.blockManedgerName}</span>
        <p>
          <em
            dangerouslySetInnerHTML={{
              __html: data?.blockManedger,
            }}
          />
        </p>
      </div>
    </div>
  );
};
