import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

export const TagRed = ({ link, name }) => {
  return (
    <li className="item-tag">
      <Link to={link}>{name}</Link>
    </li>
  );
};
