import axios from "axios";
import { contactsApiServer } from "../config";

const sendContactsUrl = `${contactsApiServer}/api/v1/contacts/send_contacts`;

export const sendContacts = async (
  submitData,
  handlerSuccess,
  handlerError
) => {
  try {
    const result = await axios.post(sendContactsUrl, submitData);
    if (handlerSuccess && !result.data.error) {
      handlerSuccess();
    }
  } catch {
    if (handlerError) {
      handlerError();
    }
  }
};
