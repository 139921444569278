import React from "react";
import "./style.scss";

export const LinkStore = ({ linkItem, url }) => {
  return (
    <a
      className="link-store"
      target="_blank"
      href={url}
      rel="noopener noreferrer"
    >
      <div>
        <img src={`${linkItem.icon}`} alt="" className="icon-stack" />
        <img
          src={`${linkItem.iconVisited}`}
          alt=""
          className="icon-stack-visited"
        />
      </div>
      <div className="text-wrap">
        <div className="text">Доступно в</div>
        <div className="name">{linkItem.name}</div>
      </div>
    </a>
  );
};
