import React, { useState } from "react";
import "./style.scss";
import { PortfolioItem } from "../../../ui";

const data = {
  en: {
    title: "Portfolio",
    projects: [
      {
        image: "/img/giveaway.webp",
        h1: "How we developed a financial app for the Nigerian market and adapted it for the US",
        text: "A few years ago, ItFox was approached by a Nigerian resident who wanted to launch a startup with the main goal of facilitating financial transactions in the country. Having lived in Nigeria for a long time, he recognized the imperfections in the existing financial system and formed specific business requirements with which he came to ItFox. After the first consultation, the technical department decided to develop a cross-platform mobile application, which became a convenient tool for users and an optimal solution in terms of development time and cost for the client.",
        id: "giveawayapp",
      },
      {
        image: "/img/fotobox-case-512.webp",
        h1: "Automation system of catering accounting of Swedish line «FotoBox»",
        text: "In this case study we talk about the automation system «FotoBox», which allows to reduce the cost of purchasing food for the Swedish line of the hotel to 100 rubles per guest. At the same time, the quality of food is not reduced, as the savings are made by reducing unpopular (constantly written off) dishes on the basis of analytical data provided by the system. «FotoBox» is a fully automated program that eliminates the human factor, including abuse by staff. After implementing the system at the Swedish line of the hotel with a capacity of 1000 people, the company - catering outsourcer «London Restaurant Group» managed to save 100 grams from 1 kilogram of food. This amounts to 36.5 tons per year. In monetary terms, the savings on one meal - from 75 to 100 rubles, which per year translates into a very decent amount - from 18 to 28 million rubles. Read the case study to find out how we did it",
        id: "fotobox",
      },
      {
        image: "/img/jar-bird.webp",
        h1: "How we developed an app and a website for a network of internet department stores and implemented a loyalty program.",
        text: "«Zhar. Ptitsa» is a Norilsk chain of department stores. The company promotes the values of healthy and varied nutrition, and shapes the business community in Norilsk. «Zhar. Ptitsa» is characterized by high quality of both products and service. In order to make the quality of service even better, the company asked us to develop an IT system consisting of 3 components: a 1C accounting system, a cross-platform mobile application of the online store for IOS and Android platforms, and a website on CMS Bitrix with food delivery, a loyalty program, and the ability information about the products by barcode reading.",
        id: "zharptica",
      },
    ],
  },
  ru: {
    title: "Портфолио",
    projects: [
      {
        image: "/img/giveaway.webp",
        h1: "Как мы разработали финансовое приложение для рынка Нигерии и адаптировали его под США",
        text: "Несколько лет назад в компанию ItFox обратился житель Нигерии, который хотел запустить стартап, главной целью которого было облегчить проведение финансовых транзакций в стране. Проживая долгое время на территории Нигерии, он понимал несовершенство существующей финансовой системы и сформировал конкретные бизнес требования, с которыми и пришел в ItFox. По прошествии первой консультации технический отдел принял решение о кроссплатформенной разработке мобильного приложения, которое стало удобным инструментом для пользователей и оптимальным решением в части сроков и стоимости разработки для клиента.",
        id: "giveawayapp",
      },
      {
        image: "/img/fotobox-case-512.webp",
        h1: "Система автоматизации учета питания шведской линии “FotoBox”",
        text: "В данном кейсе мы рассказываем о системе автоматизации «Фотобокс», которая позволяет сократить расходы на закупку продуктов питания для шведской линии отеля до 100 рублей на рацион одного гостя. При этом качество питания не снижается, так как экономия происходит за счет сокращения непопулярных (постоянно списываемых) блюд на основании аналитических данных, предоставляемых системой. «Фотобокс» – полностью автоматизированная программа, которая исключает человеческий фактор, в том числе злоупотребления со стороны персонала. После внедрения системы на шведской линии отеля вместимостью 1000 человек, компании – аутсорсеру питания «Лондон Ресторан Групп» удалось сэкономить 100 грамм с 1 килограмма еды. В год это составляет 36,5 тонн. В денежном же выражении экономия на одном рационе - от 75 до 100 рублей, что в год выливается в очень приличную сумму - от 18 до 28 миллионов рублей. Как нам это удалось, читайте в кейсе",
        id: "fotobox",
      },
      {
        image: "/img/jar-bird.webp",
        h1: "Как мы разработали приложение и сайт для сети интернет-универсамов и внедрили программу лояльности",
        text: "«Жар. Птица» — норильская сеть универсамов. Компания продвигает ценности здорового и разнообразного питания, а также формирует бизнес-сообщество в Норильске. «Жар. Птица» отличается высоким качеством как продукции, так и сервиса. Для того, чтобы сделать качество сервиса ещё лучше, компания обратилась к нам за разработкой IT-системы из 3-х компонентов: система учета 1С, кроссплатформенное мобильное приложение интернет-магазина для платформ IOS и Android, сайт на CMS Bitrix с возможностью доставки продуктов питания, программой лояльности и возможностью получать необходимую информацию о товарах при помощи считывания штрихкода.",
        id: "zharptica",
      },
    ],
  },
};

export const DEFAULT_COUNT = 3;

export const PortfolioSection = ({ lang }) => {
  const cases = window?.cases[lang];
  const [countView, setCountView] = useState(DEFAULT_COUNT);

  return (
    <section className="base-section">
      <div className="container">
        <div className="title">{data[lang].title}</div>
        <div className="list-portfolio">
          {(cases?.length ? cases : data[lang].projects)
            .slice(0, countView)
            .map((el, idx) => (
              <PortfolioItem
                key={`portfolio-item-${idx}`}
                info={el}
                btn={lang === "ru" ? "к проекту" : "to project"}
                isServerData={!!cases?.length}
                lang={lang}
              />
            ))}
        </div>
        {!!(
          (cases?.length ? cases.length : data[lang].projects) > countView
        ) && (
          <div
            className="btn-action"
            onClick={() => setCountView(countView + DEFAULT_COUNT)}
          >
            {lang === "ru" ? "Показать ещё" : "See all"}
          </div>
        )}
      </div>
    </section>
  );
};
