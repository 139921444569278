import React from "react";
import "./style.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const PortfolioItem = ({ info, btn, isServerData, lang }) => {
  const stringWithoutTags = (str) => str.replace(/<[^>]*>/g, "");
  return (
    <div className="portfolio-item">
      <img src={info.image} alt="" className="portfolio-img" />
      <div className="content-block">
        <div className="subtitle clip-three">
          {isServerData ? stringWithoutTags(info.h1) : info.h1}
        </div>
        <div className="description">
          {isServerData ? stringWithoutTags(info.text) : info.text}
        </div>
        <Link
          to={`/portfolio/case/${info.id}/${lang}`}
          className="go-to-project"
        >
          <span>{btn}</span>
          <span className="ico-arrow">
            <img
              className="arrow"
              width={24}
              height={24}
              src="/img/arrow-down.svg"
              alt=""
            />
          </span>
        </Link>
      </div>
    </div>
  );
};
