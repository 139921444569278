import React from "react";
import "./style.scss";

export const ContactItem = ({ url, img, alt, linkText, ariaLabel }) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={url}
      aria-label={ariaLabel}
    >
      <img width={24} height={24} src={img} alt={alt} />
      <div className="contact-items">{linkText}</div>
    </a>
  );
};
