import React from "react";
import "./style.scss";

export const Technologies = ({ lang, data }) => {
  return (
    <>
      <div className="row">
        <div className="col-lg-4">
          <div className="technology-item">
            <span className="technology-item_icon">
              <img
                width="90"
                height="78"
                src="/img/backend-case.svg"
                alt="Бэкэнд"
              />
            </span>
            <div className="technology-item_desc">
              <span className="technology-item_title">
                {lang === "ru" ? "Бэкэнд" : "Backend"}
              </span>
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.blockTechnologiesBackend,
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="technology-item">
            <span className="technology-item_icon">
              <img
                width="90"
                height="78"
                src="/img/methodology.svg"
                alt="Методология"
              />
            </span>
            <div className="technology-item_desc">
              <span className="technology-item_title">
                {lang === "ru" ? "Методология" : "Methodology"}
              </span>
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.blockTechnologiesMethodology,
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="technology-item">
            <span className="technology-item_icon">
              <img
                width="90"
                height="78"
                src="/img/frontend.svg"
                alt="Фронтенд"
              />
            </span>
            <div className="technology-item_desc">
              <span className="technology-item_title">
                {lang === "ru" ? "Фронтенд" : "Frontend"}
              </span>

              <p
                dangerouslySetInnerHTML={{
                  __html: data?.blockTechnologiesFrontend,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
