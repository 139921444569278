import React, { useState } from "react";
import "./style.scss";

export const LinksNetwork = ({ bg = "#141414", color = "#fff", vcru = "" }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div className="list-network">
      <a
        target="_blank"
        href="https://vc.ru/u/331647-elena-nazarova"
        aria-label="About us on vc"
        rel="noopener noreferrer"
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <img
          width="32"
          height="32"
          src={`/img/vcru${isHover ? "-orange" : vcru}.svg`}
          alt=""
        />
      </a>
      <a
        target="_blank"
        href="https://t.me/itfox_webs"
        rel="noopener noreferrer"
        aria-label="Our telegram"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.9974 29.3337C8.6334 29.3337 2.66406 23.3643 2.66406 16.0003C2.66406 8.63633 8.6334 2.66699 15.9974 2.66699C23.3614 2.66699 29.3307 8.63633 29.3307 16.0003C29.3307 23.3643 23.3614 29.3337 15.9974 29.3337ZM11.8507 17.5603L11.8681 17.551L13.0281 21.3777C13.1774 21.7923 13.3827 21.867 13.6321 21.8323C13.8827 21.799 14.0147 21.6643 14.1787 21.507L15.7627 19.9763L19.1627 22.4937C19.7841 22.8363 20.2307 22.659 20.3854 21.9177L22.5947 11.4883C22.8387 10.5177 22.4121 10.1283 21.6587 10.4377L8.6814 15.451C7.79606 15.8057 7.8014 16.3017 8.5214 16.5217L11.8507 17.5617V17.5603Z"
            style={{ fill: bg }}
          />
        </svg>
      </a>
      <a
        target="_blank"
        href="https://vk.com/itfox_web"
        rel="noopener noreferrer"
        aria-label="Our VK"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.2938 8.25C30.5163 7.545 30.2938 7.0325 29.3094 7.0325H26.0362C25.2106 7.0325 24.8294 7.48125 24.6069 7.96125C24.6069 7.96125 22.9231 12.0612 20.5719 14.7194C19.8094 15.4881 19.46 15.7444 19.0469 15.7444C18.8244 15.7444 18.5381 15.4881 18.5381 14.7837V8.21688C18.5381 7.38438 18.2844 7 17.585 7H12.4375C11.9288 7 11.6112 7.38438 11.6112 7.76875C11.6112 8.56938 12.7869 8.76187 12.9137 11.0037V15.875C12.9137 16.9319 12.7263 17.125 12.31 17.125C11.1981 17.125 8.4975 12.9931 6.8775 8.285C6.5625 7.3525 6.2425 7 5.41625 7H2.11188C1.15875 7 1 7.44813 1 7.92875C1 8.79375 2.11188 13.1494 6.17875 18.9144C8.87937 22.8519 12.7237 24.9681 16.1869 24.9681C18.2844 24.9681 18.5381 24.4875 18.5381 23.6869V20.7081C18.5381 19.7475 18.7288 19.5875 19.3962 19.5875C19.8725 19.5875 20.7306 19.8431 22.6688 21.7331C24.8925 23.975 25.2738 25 26.5131 25H29.7856C30.7388 25 31.1838 24.5194 30.9294 23.5906C30.6437 22.6619 29.5631 21.3169 28.165 19.7156C27.4025 18.8194 26.2587 17.8263 25.9094 17.3456C25.4331 16.705 25.56 16.4488 25.9094 15.8725C25.8775 15.8725 29.8813 10.1713 30.2938 8.2475"
            style={{ fill: bg }}
          />
        </svg>
      </a>
    </div>
  );
};
