import React from "react";
import "./style.scss";
import { StackItem } from "../../../ui";

const data = {
  en: {
    title: "We use a modern development stack",
    stack: [
      {
        name: "Frontend",
        icon: "/img/front-stack.svg",
        description: "Vue, React, Angular, AR/VR, PostgreSQL",
      },
      {
        name: "Backend",
        icon: "/img/backend-stack.svg",
        description: "Python, Laravel, Django, Node, Typescript",
      },
      {
        name: "Infrastructure",
        icon: "/img/infrastructure.svg",
        description: "Git, Kubernetes",
      },
      {
        name: "Integrations",
        icon: "/img/integrations.svg",
        description: "GraphQL, Rest API, WebSocket, FastAPI, Asterisk",
      },
      {
        name: "Mobile applications",
        icon: "/img/mob-app.svg",
        description: "Flutter, React Native, Unity",
      },
    ],
  },
  ru: {
    title: "Применяем современный стек разработки",
    stack: [
      {
        name: "Frontend",
        icon: "/img/front-stack.svg",
        description: "Vue, React, Angular, AR/VR, PostgreSQL",
      },
      {
        name: "Backend",
        icon: "/img/backend-stack.svg",
        description: "Python, Laravel, Django, Node, Typescript",
      },
      {
        name: "Инфрастуктура",
        icon: "/img/infrastructure.svg",
        description: "Git, Kubernetes",
      },
      {
        name: "Интеграции",
        icon: "/img/integrations.svg",
        description: "GraphQL, Rest API, WebSocket, FastAPI, Asterisk",
      },
      {
        name: "Мобильные приложения",
        icon: "/img/mob-app.svg",
        description: "Flutter, React Native, Unity",
      },
    ],
  },
};
export const StackSection = ({ lang }) => {
  return (
    <section className="base-section stack">
      <div className="container">
        <div className="title">{data[lang].title}</div>
        <div className="list-stack">
          {data[lang].stack.map((el, idx) => (
            <StackItem info={el} key={`stack-item-${idx}`} />
          ))}
        </div>
      </div>
    </section>
  );
};
