import React from "react";
import "./style.scss";

export const MediaItem = ({ info, textLink }) => {
  return (
    <div className="media-item">
      <div className="title">{info.title}</div>
      <div className="wrapper-content">
        <div className="description">{info.description}</div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={info.link}
          className="link-media"
        >
          <span>{textLink}</span>
          <span className="arrow-span">
            <img className="arrow" alt="" src="/img/arrow-right.svg" />
          </span>
        </a>
      </div>
    </div>
  );
};
