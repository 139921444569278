import React from "react";
import "./style.scss";

export const Checkbox = ({
  label,
  isChecked,
  onChange,
  name,
  required = false,
  errorMessage = "",
}) => {
  return (
    <div className="wrapper-checkbox">
      <input
        type="checkbox"
        className="custom-checkbox"
        id={name}
        name={name}
        checked={isChecked}
        onChange={() => onChange(!isChecked)}
        required={required}
      />
      <label htmlFor={name}>{label}</label>
      {!!errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};
