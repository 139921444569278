import React from "react";
import "./style.scss";

export const ContactCard = ({ text, children }) => {
  return (
    <div className="contact-block-wrap-new">
      <div className="contact-block-title">{text}</div>
      <>{children}</>
    </div>
  );
};
