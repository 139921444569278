import React from "react";
import "./style.scss";

export const DiscussBox = ({ lang, onClick }) => {
  return (
    <div className="setup_discuss-box">
      <div className="h4">
        {lang === "ru"
          ? "Столкнулись с аналогичной задачей?"
          : "Have you encountered a similar problem?"}{" "}
        <br />
        <span className="medium-text">
          {" "}
          {lang === "ru" ? "Поможем решить." : "We will help you decide."}
        </span>
      </div>
      <div
        onClick={onClick}
        data-toggle="modal"
        className="main-btn js_discuss-btn btn btn-block"
      >
        {lang === "ru" ? "Связаться" : "Contact"}
      </div>
    </div>
  );
};
