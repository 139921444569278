import React from "react";
import "./style.scss";
import BaseHeader from "../../components/baseHeader";
import { Footer } from "../../components/sections";
import { LinksNetwork } from "./LinksNetwork";
import { useEffect } from "react";

export const Notification = ({ page, lang }) => {
  const isRu = lang === "ru";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main root notification-page" page={page}>
      <BaseHeader lang={lang} page={page} isNotification={true} />
      <div className="container content-notification">
        <div className="shadow" />
        <div className="title">
          <div className="main-title">
            {isRu ? "Спасибо за вашу заявку" : "Thank you for your application"}
          </div>
          <div className="subtitle">
            {isRu
              ? "Менеджер свяжется с вами в ближайшее время"
              : "The manager will contact you soon"}
          </div>
        </div>
        <div className="wrapper-info">
          <div className="info-text">
            <div className="subtitle">
              {isRu
                ? "Подписывайтесь на наши соцсети"
                : "Subscribe to our social networks"}
            </div>
            <div className="description">
              {isRu
                ? `Мы рассказываем о том, как разрабатывать программное обеспечение
              быстро, качественно и в рамках бюджета. Также делимся полезными
              кейсами из практики.`
                : `We talk about how to develop software quickly, efficiently and within budget. We also share useful cases from practice.`}
            </div>
            <LinksNetwork vcru="-black"/>
          </div>
          <div className="wrapper-symbol">
            <div className="bg-circle" />
            <img src="/img/fox.webp" alt="" />
          </div>
        </div>
        <div className="shadow" />
      </div>
      <Footer lang={lang} />
    </div>
  );
};
