import React from "react";
import "./style.scss";

export const Budget = ({ data, value, setValue, errorMessage }) => {
  return (
    <div className="wrapper-budget">
      <div className="name">
        <span>{data.name}</span>
        <span>*</span>
      </div>
      <div className="list-budget">
        {data.values.map((el, idx) => (
          <div
            key={`item-budget-${idx}`}
            className={`item-budget ${el === value ? "selected" : ""}`}
            onClick={() => setValue(el)}
          >
            {el}
          </div>
        ))}
      </div>
      {!!errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};
