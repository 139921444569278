import React from "react";
import "./style.scss";

const data = {
  ru: {
    title: "Разработка программного обеспечения для бизнеса",
    subTitle: "Наши услуги",
    services: [
      "Мобильная разработка",
      "Веб-разработка",
      "Автоматизация процессов",
      "Разработка дизайна",
      "Аналитика",
    ],
  },
  en: {
    title: "Business software development",
    subTitle: "Our services",
    services: ["Mobile", "Web", "Analytics", "Ui/Ux", "Process Automation"],
  },
};

export const ServicesSection = ({ lang }) => {
  const scrollToAboutCompany = () => {
    const aboutCompanyBlock = document.getElementById("about-company");
    const header = document.querySelector(".main-header");
    if (aboutCompanyBlock && header) {
      window.scrollTo({
        top: aboutCompanyBlock.offsetTop - header.clientHeight,
        behavior: "smooth",
      });
    }
  };
  return (
    <section className="bg-services">
      <div className="container">
        <div className="wrapper-content">
          <div className="title">{data[lang].title}</div>
          <div className="wrapper-services">
            <div className="block-services">
              <div className="sub-title">{data[lang].subTitle}</div>
              <div className="list-services">
                {data[lang].services.map((el, idx) => (
                  <div className="service-item" key={`service-item-${idx}`}>
                    <span className="name">{el}</span>
                    {!!(idx !== data[lang].services.length - 1) && (
                      <span className="separate" />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <img
              className="img-scroll"
              width="48"
              height="48"
              alt=""
              src="/img/scroll.svg"
              onClick={scrollToAboutCompany}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
