import React from "react";
import "./style.scss";

export const GetBtn = ({
  img,
  onClick,
  isShow,
  lang,
  textShowRu,
  textShowEn,
}) => {
  return (
    <div className="img-btn">
      <button className="button btn get-btn" onClick={onClick}>
        <img width="24" height="24" className="get-btn-img" src={img} alt="" />
        {isShow === false
          ? lang === "ru"
            ? textShowRu
            : textShowEn
          : lang === "ru"
          ? "Скрыть"
          : "Hide"}
      </button>
    </div>
  );
};
