import React from "react";
import "./style.scss";
import { LinkStore } from "../../../ui";

const links = {
  ru: [
    {
      name: "AppMarket",
      icon: "/img/logo-apple-link.svg",
      iconVisited: "/img/logo-apple-link-light.svg",
    },
    {
      name: "Google Play",
      icon: "/img/logo-google-playstore.svg",
      iconVisited: "/img/logo-google-playstore-light.svg",
    },
    {
      name: "AppGallery",
      icon: "/img/huawei-appgallery-seeklogo.svg",
      iconVisited: "/img/huawei-appgallery-seeklogo-light.svg",
    },
    {
      name: "RuStore",
      icon: "/img/rustore_logo.svg",
      iconVisited: "/img/rustore_logo-light.svg",
    },
    {
      name: "Веб-версии",
      icon: "/img/web-svgrepo-com.svg",
      iconVisited: "/img/web-svgrepo-com-light.svg",
    },
  ],

  en: [
    {
      name: "AppMarket",
      icon: "/img/logo-apple-link.svg",
      iconVisited: "/img/logo-apple-link-light.svg",
    },
    {
      name: "Google Play",
      icon: "/img/logo-google-playstore.svg",
      iconVisited: "/img/logo-google-playstore-light.svg",
    },
    {
      name: "AppGallery",
      icon: "/img/huawei-appgallery-seeklogo.svg",
      iconVisited: "/img/huawei-appgallery-seeklogo-light.svg",
    },
    {
      name: "RuStore",
      icon: "/img/rustore_logo.svg",
      conVisited: "/img/rustore_logo-light.svg",
    },
    {
      name: "Web version",
      icon: "/img/web-svgrepo-com.svg",
      iconVisited: "/img/web-svgrepo-com-light.svg",
    },
  ],
};

export const ListStoreLinks = ({
  lang,
  appMarketLink,
  googlePlayLink,
  appGalleryLink,
  rustoreLink,
  webLink,
}) => {
  return (
    <>
      <div className="list-links">
        {appMarketLink && (
          <LinkStore linkItem={links[lang][0]} url={appMarketLink} />
        )}
        {googlePlayLink && (
          <LinkStore linkItem={links[lang][1]} url={googlePlayLink} />
        )}
        {appGalleryLink && (
          <LinkStore linkItem={links[lang][2]} url={appGalleryLink} />
        )}
        {rustoreLink && (
          <LinkStore linkItem={links[lang][3]} url={rustoreLink} />
        )}
        {webLink && <LinkStore linkItem={links[lang][4]} url={webLink} />}
      </div>
    </>
  );
};
