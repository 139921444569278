import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";

export const TooltipHeader = ({ data, isScroll, page }) => {
  return (
    <div className={`tooltip-header ${isScroll ? "white" : ""}`}>
      <div className="round" />
      <div className="bg-gradient">
        <div className="list-items">
          {data.map((el, idx) => (
            <Link
              key={`link-tooltip-${idx}`}
              to={el.path}
              className={`item ${el?.path?.includes(page) ? "active" : ""}`}
            >
              {el.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
