import React from "react";
import "./style.scss";

export const ReviewBlock = ({ data }) => {
  return (
    <div className="setup_item-box section">
      <span id="block-3" />
      <div className="review-item">
        <span className="quotes">“</span>
        <span className="review-item_header">
          {data?.imgLogo && <img alt="alt" src={data?.imgLogo} />}
        </span>
        <div className="review-item_body">
          <div className="review-item_mod-img">
            <img alt="alt" src={data.imgReview} />
          </div>
          <div className="review-item_desc">
            <span className="review-item_title">{data.blockReviewName}</span>
            <span className="review-item_position">{data.blockReviewJob}</span>
            <p
              dangerouslySetInnerHTML={{
                __html: data?.blockReview,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
