import React from "react";
import "./style.scss";

export const StackItem = ({ info }) => {
  return (
    <div className="stack-item">
      <div className="name">{info.name}</div>
      <img
        width="105"
        height="105"
        src={info.icon}
        alt=""
        className="icon-stack"
      />
      <div className="description">{info.description}</div>
    </div>
  );
};
